import * as React from 'react';
import { VStack } from '@chakra-ui/react';
import { Providers } from './providers';
import { Header } from './components/layout/Header';
import { Flex } from '@chakra-ui/layout';
import ChatPage from './pages/ChatPage';
import { FronteggProvider } from '@frontegg/react';
import { useEffect } from 'react';

const contextOptions = {
  baseUrl: process.env.REACT_APP_FRONTEGG_BASE_URL || '',
  clientId: process.env.REACT_APP_FRONTEGG_CLIENT_ID || '',
};

const authOptions = {
  keepSessionAlive: true, // Uncomment this in order to maintain the session alive
};

export const App = () => {
  const handleCopy = (e: ClipboardEvent) => {
    e.preventDefault();

    const selection = document.getSelection();
    if (selection) {
      e.clipboardData?.setData('text/plain', selection.toString());
    }
  };

  useEffect(() => {
    document.addEventListener('copy', handleCopy);
    return () => {
      document.removeEventListener('copy', handleCopy);
    };
  }, []);
  return (
    <FronteggProvider
      contextOptions={contextOptions}
      authOptions={authOptions}
      hostedLoginBox={true}
    >
      <Providers>
        <Header />
        <Flex h={'calc(100vh - 4em)'}>
          {/*<Nav />*/}
          <VStack flex={1} align={'left'}>
            <ChatPage />
          </VStack>
        </Flex>
      </Providers>
    </FronteggProvider>
  );
};
