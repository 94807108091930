import { FormControl, FormLabel, Select } from '@chakra-ui/react';
import { HStack, SimpleGrid } from '@chakra-ui/layout';
import { Input } from '@chakra-ui/input';
import { FeatureModal } from './FeatureModal';
import { Feature, SocialOptions, SocialPlatform } from '../../types';
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTiktok,
  FaXTwitter,
  FaYoutube,
} from 'react-icons/fa6';
import { SocialButton } from '../buttons/SocialButton';
import { useFeatureStore } from '../../stores';
import { useState } from 'react';

interface SocialModalProps {
  currentFeature: Feature;
  options: SocialOptions;
  setOptions: (options: any) => void;
  isOpen: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

const iconSize = 25;
export const SocialModal = ({
  currentFeature,
  options,
  setOptions,
  isOpen,
  onSubmit,
  onCancel,
}: SocialModalProps) => {
  const [errors, setErrors] = useState<SocialOptions | null>(null);
  const setCurrentFeature = useFeatureStore((state) => state.setCurrentFeature);
  const handleClick = (feature: Feature) => {
    let platform = '';
    switch (feature) {
      case Feature.Social_X:
        platform = SocialPlatform.X;
        break;
      case Feature.Social_TikTok:
        platform = SocialPlatform.TikTok;
        break;
      case Feature.Social_Instagram:
        platform = SocialPlatform.Instagram;
        break;
      case Feature.Social_Facebook:
        platform = SocialPlatform.Facebook;
        break;
      case Feature.Social_LinkedIn:
        platform = SocialPlatform.LinkedIn;
        break;
      case Feature.Social_YouTube:
        platform = SocialPlatform.YouTube;
        break;
    }
    setOptions({ ...options, platform });
    setCurrentFeature(feature);
  };

  const validate = () => {
    const _errors: any = {};

    if (!options.tone) _errors['tone'] = 'Tone is required';
    if (!options.length) _errors['length'] = 'Length is required';
    if (!options.emojis) _errors['emojis'] = 'Emojis is required';
    if (!options.quantity) _errors['quantity'] = 'Quantity is required';
    if (!options.topic) _errors['topic'] = 'Topic is required';

    if (Object.keys(_errors).length === 0) {
      onSubmit();
    } else {
      setErrors(_errors);
    }
  };
  return (
    <FeatureModal
      title={'Social Media'}
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={validate}
    >
      <SimpleGrid columns={3} spacing={5}>
        <SocialButton
          selected={currentFeature === Feature.Social_X}
          label={SocialPlatform.X}
          icon={<FaXTwitter size={iconSize} />}
          onClick={() => handleClick(Feature.Social_X)}
        />
        <SocialButton
          selected={currentFeature === Feature.Social_TikTok}
          label={SocialPlatform.TikTok}
          icon={<FaTiktok size={iconSize} />}
          onClick={() => handleClick(Feature.Social_TikTok)}
        />
        <SocialButton
          selected={currentFeature === Feature.Social_Instagram}
          label={SocialPlatform.Instagram}
          icon={<FaInstagram size={iconSize} />}
          onClick={() => handleClick(Feature.Social_Instagram)}
        />
        <SocialButton
          selected={currentFeature === Feature.Social_Facebook}
          label={SocialPlatform.Facebook}
          icon={<FaFacebook size={iconSize} />}
          onClick={() => handleClick(Feature.Social_Facebook)}
        />
        <SocialButton
          selected={currentFeature === Feature.Social_LinkedIn}
          label={SocialPlatform.LinkedIn}
          icon={<FaLinkedin size={iconSize} />}
          onClick={() => handleClick(Feature.Social_LinkedIn)}
        />
        <SocialButton
          selected={currentFeature === Feature.Social_YouTube}
          label={SocialPlatform.YouTube}
          icon={<FaYoutube size={iconSize} />}
          onClick={() => handleClick(Feature.Social_YouTube)}
        />
      </SimpleGrid>
      {currentFeature.indexOf('social_') > -1 && (
        <>
          <HStack my={'1em'}>
            <FormControl isRequired={true} isInvalid={!!errors?.tone}>
              <FormLabel>Tone</FormLabel>
              <Select
                defaultValue={options.tone}
                onChange={(e) =>
                  setOptions({ ...options, tone: e.target.value })
                }
              >
                <option value=""></option>
                <option value="Sensai">Sensai</option>
                <option value="Manager">Manager</option>
                <option value="Sales person">Sales person</option>
              </Select>
            </FormControl>
            <FormControl isRequired={true} isInvalid={!!errors?.length}>
              <FormLabel>Length</FormLabel>
              <Select
                defaultValue={options.length}
                onChange={(e) =>
                  setOptions({ ...options, length: e.target.value })
                }
              >
                <option value=""></option>
                <option value="1 liner">1 Liner</option>
                <option value="2 sentences">2 Sentences</option>
                <option value="3 sentences">3 Sentences</option>
                <option value="1 paragraph">1 Paragraph</option>
                <option value="2 paragraphs">2 Paragraphs</option>
                <option value="3 paragraphs">3 Paragraphs</option>
              </Select>
            </FormControl>
            <FormControl isRequired={true} isInvalid={!!errors?.emojis}>
              <FormLabel>Emojis?</FormLabel>
              <Select
                defaultValue={options.emojis}
                onChange={(e) =>
                  setOptions({ ...options, emojis: e.target.value })
                }
              >
                <option value=""></option>
                <option value="Use">Use</option>
                <option value="Do Not User">Do Not Use</option>
              </Select>
            </FormControl>
            <FormControl isRequired={true} isInvalid={!!errors?.quantity}>
              <FormLabel>Quantity</FormLabel>
              <Select
                defaultValue={options.quantity}
                onChange={(e) =>
                  setOptions({ ...options, quantity: e.target.value })
                }
              >
                <option value=""></option>
                <option value="1">1</option>
                <option value="3">3</option>
                <option value="5">5</option>
                <option value="10">10</option>
              </Select>
            </FormControl>
          </HStack>
          <FormControl isRequired={true} isInvalid={!!errors?.topic}>
            <FormLabel>Topic</FormLabel>
            <Input
              placeholder={'What is your post about?'}
              value={options.topic}
              onChange={(e) =>
                setOptions({ ...options, topic: e.target.value })
              }
            />
          </FormControl>
        </>
      )}
    </FeatureModal>
  );
};
