import { Icon } from '@chakra-ui/icon';

export const ObjectionResponseIcon = (props: any) => (
  <Icon viewBox="0 0 78.77 78.77" {...props}>
    <defs>
      <style>
        {
          '\n      .cls-1 {\n        fill: #fff;\n        stroke-width: 0px;\n      }\n    '
        }
      </style>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path
          fill={'currentColor'}
          d="m78.19,22.49L56.28.58c-.37-.37-.88-.58-1.4-.58h-30.98c-.52,0-1.03.21-1.4.58L.58,22.49c-.37.37-.58.88-.58,1.4v30.98c0,.52.21,1.03.58,1.4l21.91,21.91c.38.38.87.58,1.4.58h30.98c.53,0,1.03-.21,1.4-.58l21.91-21.91c.38-.38.58-.87.58-1.4v-30.98c0-.53-.21-1.03-.58-1.4Zm-3.39,31.56l-20.74,20.74h-29.34L3.97,54.05v-29.34L24.72,3.97h29.34s20.74,20.74,20.74,20.74v29.34Z"
        />
        <path
          fill={'currentColor'}
          d="m39.38,46.55c2.05,0,3.72-1.64,3.76-3.69l.48-24.69c.04-2.1-1.65-3.83-3.76-3.83h-.96c-2.11,0-3.8,1.73-3.76,3.83l.48,24.69c.04,2.05,1.71,3.69,3.76,3.69Z"
        />
        <circle fill={'currentColor'} cx={39.38} cy={57.65} r={4.29} />
      </g>
    </g>
  </Icon>
);
