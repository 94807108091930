import { PageLayout } from '../components/layout/PageLayout';
import { ChatThreadsContainer } from '../components/chat/ChatThreadsContainer';
import { ChatLayoutBody } from '../components/chat/ChatLayoutBody';
import { Box, Text } from '@chakra-ui/layout';
import { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { ChatInput } from '../components/chat/ChatInput';
import { ChatMessage } from '../components/chat/ChatMessage';
import { useToast, VStack } from '@chakra-ui/react';
import { NewChatButton } from '../components/buttons/NewChatButton';
import { useDisclosure } from '@chakra-ui/react-use-disclosure';
import { ContextHolder, useAuth } from '@frontegg/react';
import { ChatOptions } from '../components/chat/ChatOptions';
import { Feature } from '../types';
import { useFeatureStore, useMessageStore } from '../stores';
import { ModalSwitcher } from '../components/modals/ModalSwitcher';
import { getFeatureConfig } from '../utils';
import * as Sentry from '@sentry/react';
import { ChatActions } from '../components/chat/ChatActions';

export default function ChatPage() {
  const toast = useToast();
  const messageListRef = useRef(null);
  const { user } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [inputMessage, setInputMessage] = useState<string>('');
  const [isSending, setIsSending] = useState<boolean>(false);

  // STATE
  const messages = useMessageStore((state) => state.messages);
  const addMessages = useMessageStore((state) => state.addMessages);
  const updateLastMessage = useMessageStore((state) => state.updateLastMessage);
  const removeLastMessage = useMessageStore((state) => state.removeLastMessage);
  const resetMessages = useMessageStore((state) => state.resetMessages);
  const currentFeature = useFeatureStore((state) => state.currentFeature);
  const setCurrentFeature = useFeatureStore((state) => state.setCurrentFeature);
  const resetFeature = useFeatureStore((state) => state.resetCurrentFeature);
  const options = useFeatureStore((state) => state.options);
  const setOptions = useFeatureStore((state) => state.setOptions);
  const resetOptions = useFeatureStore((state) => state.resetOptions);

  const sendMessages = async (message?: string) => {
    if (isSending) return; // prevents sending more than once before complete response
    if (isOpen) onClose();
    if (inputMessage) setInputMessage(''); // resets the input message
    if (!inputMessage.trim() && !message && currentFeature === Feature.Chat)
      return; // prevents sending empty messages for chat feature
    if (
      !inputMessage.trim() &&
      messages.length > 0 &&
      !message &&
      currentFeature !== Feature.Chat
    )
      return; // prevents sending empty messages for non-chat features

    setIsSending(true);
    const config = getFeatureConfig(currentFeature, options);

    //TODO: create thread if it doesn't exist

    const _message = message || inputMessage;

    addMessages([
      {
        role: 'user',
        content:
          messages.length === 0 && currentFeature !== Feature.Chat
            ? config.initialMessage
            : _message,
      },
      {
        role: 'assistant',
        content: '',
      },
    ]);

    try {
      const response = await fetch(config.endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/event-stream',
          Authorization: `Bearer ${user?.accessToken}`,
        },
        body: JSON.stringify({
          options,
          messages: [
            ...messages,
            {
              role: 'user',
              content: _message,
            },
          ],
        }),
      });

      if (response.status === 401) {
        // log user out if token is expired
        const baseUrl = ContextHolder.getContext().baseUrl;
        window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location}`;
      }

      if (response.body) {
        const reader = response.body
          .pipeThrough(new TextDecoderStream())
          .getReader();

        let message = '';
        while (true) {
          const { value, done } = await reader.read();
          if (done) {
            setIsSending(false);

            // remove last message if it's empty
            if (message === '') {
              removeLastMessage();

              toast({
                title: 'Error',
                description:
                  'There seems to be a problem with the server. Try again.',
                status: 'error',
                duration: 5000,
                isClosable: true,
              });

              Sentry.captureMessage(
                'No message returned from OpenAI API',
                'error',
              );
            }

            break;
          }
          message += value;
          updateLastMessage(message);
        }
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  useEffect(() => {
    const scrollToBottom = () => {
      if (messageListRef.current) {
        // @ts-ignore
        messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
      }
    };
    scrollToBottom();
  }, [messages]);

  const handleSendMessage = async () => {
    await sendMessages();
  };

  const handleKeyDown = async (event: KeyboardEvent<HTMLInputElement>) => {
    const shiftPressed = event.getModifierState('Shift');
    if (event.key === 'Enter' && !shiftPressed) {
      event.preventDefault();
      if (inputMessage === '') return;
      await sendMessages();
    }
  };

  const handleCancel = () => {
    onClose();
    resetOptions();
    resetFeature();
  };

  const handleSubmit = async () => {
    console.log('submitted options:', options);
    await sendMessages();
  };

  const handleSelectFeature = (feature: Feature) => {
    setCurrentFeature(feature);
    onOpen();
  };

  const handleNewChat = () => {
    resetMessages();
    resetOptions();
    resetFeature();
  };

  const handleQuickAction = async (action: string) => {
    await sendMessages(action);
  };

  return (
    <PageLayout>
      <ChatThreadsContainer>
        <NewChatButton onClick={handleNewChat} />
      </ChatThreadsContainer>
      <ChatLayoutBody>
        <VStack w={'100%'} h={'100%'}>
          <Box w={'100%'} mt={'auto'} overflowY={'scroll'} ref={messageListRef}>
            {messages.map((message, index) => (
              <ChatMessage message={message} key={`message-${index}`} />
            ))}
          </Box>
          {messages.length === 0 && (
            <Box w={'50em'}>
              <ChatOptions onClick={handleSelectFeature} />
            </Box>
          )}
          {messages.length > 0 && <ChatActions onClick={handleQuickAction} />}
          <Box bgColor={'chatBackground'}>
            <ChatInput
              inputMessage={inputMessage}
              setInputMessage={setInputMessage}
              sendMessage={handleSendMessage}
              handleKeyDown={handleKeyDown}
            />
          </Box>
          <Text color={'secondaryText'} fontSize={'sm'}>
            Sensai can make mistakes. Consider checking important information.
          </Text>
        </VStack>
      </ChatLayoutBody>
      <ModalSwitcher
        currentFeature={currentFeature}
        options={options}
        setOptions={setOptions}
        isOpen={isOpen}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
      />
    </PageLayout>
  );
}
