import { Card, CardBody, CardHeader } from '@chakra-ui/card';
import { Heading, HStack, Text } from '@chakra-ui/layout';
import { ScaleFade, VStack } from '@chakra-ui/react';

interface ChatOptionsCardProps {
  size?: 'sm' | 'md' | 'lg';
  title?: string;
  text?: string;
  icon?: any;
  onClick?: () => void;
}
export const ChatOptionsCard = ({
  size = 'md',
  title,
  text,
  icon,
  onClick,
}: ChatOptionsCardProps) => {
  return (
    <ScaleFade initialScale={0.7} delay={0.5} in={true}>
      <Card
        _hover={{
          cursor: 'pointer',
          opacity: 1,
          textColor: 'featureTextColor',
        }}
        onClick={onClick}
        borderColor={'lagunaTeal'}
        borderWidth={1}
        opacity={0.7}
      >
        <HStack w={'100%'} spacing={0}>
          {icon}
          <VStack w={'100%'}>
            {title && (
              <CardHeader w={'100%'} p={3} pb={0}>
                <Heading size={size}>{title}</Heading>
              </CardHeader>
            )}
            {text && (
              <CardBody w={'100%'} p={3} pt={0}>
                <Text>{text}</Text>
              </CardBody>
            )}
          </VStack>
        </HStack>
      </Card>
    </ScaleFade>
  );
};
