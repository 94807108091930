import { Icon } from '@chakra-ui/icon';

export const SensaiIcon = (props: any) => (
  <Icon viewBox="0 0 93.25 105.45" {...props}>
    <defs>
      <style>
        {
          '\n      .cls-1 {\n        fill: #231f20;\n        stroke-width: 0px;\n      }\n    '
        }
      </style>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <path
        fill={'currentColor'}
        d="m61.94,24.45h-20.3s0-24.45,0-24.45L11.1,25.9c-22.34,18.94-8.6,55.11,20.22,55.1h20.3s0,24.45,0,24.45l30.54-25.9c22.32-18.93,8.61-55.11-20.22-55.1Zm11.91,27.34c1.55,4.7.19,10.09-3.8,13.47l-18.42,15.62v-18.62s-20.3,0-20.3,0c-7.36,0-13.07-6.27-12.49-13.55.27-3.31,1.85-6.38,4.39-8.53l18.42-15.62v18.62s20.3,0,20.3,0c5.69.01,10.3,3.73,11.9,8.61Z"
      />
    </g>
  </Icon>
);
