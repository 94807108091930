import { Icon } from '@chakra-ui/icon';

export const VideoIdeasIcon = (props: any) => (
  <Icon viewBox="0 0 69.92 75.48" {...props}>
    <defs>
      <style>
        {
          '\n      .cls-1 {\n        fill: #fff;\n        stroke-width: 0px;\n      }\n    '
        }
      </style>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path
          fill={'currentColor'}
          d="m54.69,33.21l-1.98.16,1.98-.17c-.79-9.32-8.15-16.92-17.45-17.97-.77-.09-1.53-.13-2.28-.13-10.93,0-19.8,8.86-19.8,19.8,0,7.04,3.68,13.24,9.23,16.74,1.93,1.23,3.11,3.22,3.11,5.38v1.26s0,0,0,0v9.92c0,4.02,3.25,7.27,7.27,7.27h.73c4.02,0,7.27-3.25,7.27-7.27v-9.87s0-.03,0-.04v-1.25c0-2.24,1.07-4.34,2.86-5.47,5.48-3.51,9.12-9.67,9.12-16.67,0-.56-.02-1.12-.07-1.69Zm-3.9,1.69c0,5.6-2.9,10.51-7.3,13.33-2.81,1.81-4.42,4.86-4.64,8.07,0,0,0,0,0,0h-7.42s0,0,0,0c-.24-3.34-2.14-6.27-4.91-8.02-4.44-2.81-7.38-7.75-7.38-13.38,0-4.38,1.77-8.32,4.64-11.19,2.87-2.87,6.81-4.63,11.19-4.64.6,0,1.21.03,1.83.1,7.38.81,13.33,6.96,13.94,14.36.04.46.06.91.06,1.36Zm-11.99,33.3c0,1.82-1.48,3.29-3.3,3.3h-.73c-1.82,0-3.29-1.48-3.3-3.3v-.08h7.32v.08Zm0-4.06h-7.33v-3.87h7.33v3.87Z"
        />
        <path
          fill={'currentColor'}
          d="m32.12,28.55c-.36-.23-.84.04-.84.47v11.8c0,.43.47.69.84.47l9.54-5.9c.35-.21.35-.72,0-.93l-9.54-5.9Z"
        />
        <path
          fill={'currentColor'}
          d="m14.97,52.1l-4.73,4.78c-.77.78-.77,2.04.01,2.81.78.77,2.04.77,2.81-.01l4.73-4.78c.77-.78.77-2.04-.01-2.81-.78-.77-2.04-.77-2.81.01Z"
        />
        <path
          fill={'currentColor'}
          d="m10.67,34.9c0-1.1-.9-1.98-2-1.97l-6.69.05C.88,32.98,0,33.88,0,34.98c0,1.1.9,1.98,2,1.97l6.69-.05c1.1,0,1.98-.9,1.97-2Z"
        />
        <path
          fill={'currentColor'}
          d="m14.98,17.74c.78.77,2.04.77,2.81-.01.77-.78.77-2.04-.01-2.81l-4.73-4.69c-.78-.77-2.04-.77-2.81.01-.77.78-.77,2.04.01,2.81l4.73,4.69Z"
        />
        <path
          fill={'currentColor'}
          d="m34.96,10.62c1.1,0,1.99-.89,1.99-1.99V1.99c0-1.1-.89-1.99-1.99-1.99s-1.99.89-1.99,1.99v6.65c0,1.1.89,1.99,1.99,1.99Z"
        />
        <path
          fill={'currentColor'}
          d="m54.94,17.74l4.73-4.69c.78-.77.79-2.03.01-2.81-.77-.78-2.03-.79-2.81-.01l-4.73,4.69c-.78.77-.79,2.03-.01,2.81.77.78,2.03.79,2.81.01Z"
        />
        <path
          fill={'currentColor'}
          d="m67.95,32.98l-6.69-.05c-1.1,0-1.99.88-2,1.97,0,1.1.88,1.99,1.97,2l6.69.05c1.1,0,1.99-.88,2-1.97,0-1.1-.88-1.99-1.97-2Z"
        />
        <path
          fill={'currentColor'}
          d="m54.96,52.1c-.77-.78-2.03-.79-2.81-.01-.78.77-.79,2.03-.01,2.81l4.73,4.78c.77.78,2.03.79,2.81.01.78-.77.79-2.03.01-2.81l-4.73-4.78Z"
        />
      </g>
    </g>
  </Icon>
);
