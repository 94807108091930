import { Icon } from '@chakra-ui/icon';

export const SmsComposerIcon = (props: any) => (
  <Icon viewBox="0 0 75.48 66.54" {...props}>
    <defs>
      <style>
        {
          '\n      .cls-1 {\n        fill: #fff;\n        stroke-width: 0px;\n      }\n    '
        }
      </style>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path
          fill={'currentColor'}
          d="m51.15,0h-26.82C10.89,0,0,10.89,0,24.33c0,13.44,10.89,24.33,24.33,24.33h2.48v15.89c0,.8.48,1.53,1.23,1.84.74.31,1.6.14,2.16-.43l17.3-17.3h3.65c13.44,0,24.33-10.89,24.33-24.33,0-13.44-10.89-24.33-24.33-24.33Zm0,44.69h-4.47c-.52,0-1.03.21-1.4.58l-14.49,14.49v-13.08c0-.52-.21-1.03-.58-1.4-.37-.37-.88-.58-1.4-.58h-4.47c-5.63,0-10.71-2.28-14.4-5.96-3.69-3.69-5.96-8.77-5.96-14.4,0-5.63,2.28-10.71,5.96-14.4,3.69-3.69,8.77-5.96,14.4-5.96h26.82c5.63,0,10.71,2.28,14.4,5.96,3.69,3.69,5.96,8.77,5.96,14.4,0,5.63-2.28,10.71-5.96,14.4-3.69,3.69-8.77,5.96-14.4,5.96Z"
        />
        <circle fill={'currentColor'} cx={19.86} cy={24.33} r={4.47} />
        <circle fill={'currentColor'} cx={37.74} cy={24.33} r={4.47} />
        <circle fill={'currentColor'} cx={55.62} cy={24.33} r={4.47} />
      </g>
    </g>
  </Icon>
);
