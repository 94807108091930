import { SimpleGrid } from '@chakra-ui/layout';
import { ChatOptionsCard } from './ChatOptionsCard';
import {
  EmailComposerIcon,
  ObjectionResponseIcon,
  SmsComposerIcon,
  SocialMediaIcon,
  TrainingIcon,
  VideoIdeasIcon,
} from '../icons';
import { Feature } from '../../types';

const size = 'sm';
const iconSize = 35;

interface ChatOptionsProps {
  onClick: (f: Feature) => void;
}

export const ChatOptions = ({ onClick }: ChatOptionsProps) => {
  return (
    <SimpleGrid
      columns={2}
      spacing={6}
      // templateColumns="repeat(auto-fill, minmax(200px, 1fr))"
    >
      <ChatOptionsCard
        title={'Email Composer'}
        text={'Craft Compelling Emails'}
        size={size}
        icon={<EmailComposerIcon boxSize={iconSize} m={5} />}
        onClick={() => onClick(Feature.Email)}
      />
      <ChatOptionsCard
        title={'SMS Composer'}
        text={'Streamline your text messages'}
        size={size}
        icon={<SmsComposerIcon boxSize={iconSize} m={5} />}
        onClick={() => onClick(Feature.Sms)}
      />
      <ChatOptionsCard
        title={'Objection Response'}
        text={'Turn objections into opportunities'}
        size={size}
        icon={<ObjectionResponseIcon boxSize={iconSize} m={5} />}
        onClick={() => onClick(Feature.ObjectionResponse)}
      />
      <ChatOptionsCard
        title={'Social Media Content'}
        text={'Create engaging social posts'}
        size={size}
        icon={<SocialMediaIcon boxSize={iconSize} m={5} />}
        onClick={() => onClick(Feature.Social)}
      />
      <ChatOptionsCard
        title={'Video Ideas'}
        text={'Inspiration for standout videos'}
        size={size}
        icon={<VideoIdeasIcon boxSize={iconSize} m={5} />}
        onClick={() => onClick(Feature.VideoIdeas)}
      />
      <ChatOptionsCard
        title={'Training Tutorials'}
        text={'Coming Soon'}
        size={size}
        icon={<TrainingIcon boxSize={iconSize} m={5} />}
        onClick={() => onClick(Feature.Training)}
      />
    </SimpleGrid>
  );
};
