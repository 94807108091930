import { create } from 'zustand';
import { Message } from '../types';

type State = {
  messages: Message[];
};

type Actions = {
  addMessages: (messages: Message[]) => void;
  updateLastMessage: (messageContent: string) => void;
  removeLastMessage: () => void;
  resetMessages: () => void;
};

export const useMessageStore = create<State & Actions>((set) => ({
  messages: [],
  addMessages: (messages) =>
    set((state) => ({ messages: [...state.messages, ...messages] })),
  updateLastMessage: (messageContent) =>
    set((state) => {
      const messages = [...state.messages];
      const lastMessage = messages[messages.length - 1];
      if (lastMessage) {
        lastMessage.content = messageContent;
      }
      return { messages };
    }),
  removeLastMessage: () =>
    set((state) => ({ messages: state.messages.slice(0, -1) })),
  resetMessages: () => set({ messages: [] }),
}));
