import { Icon } from '@chakra-ui/icon';

export const TrainingIcon = (props: any) => (
  <Icon viewBox="0 0 60.29 42.61" {...props}>
    <defs>
      <style>
        {
          '\n      .cls-1 {\n        fill: #fff;\n        stroke-width: 0px;\n      }\n    '
        }
      </style>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <path
        fill={'currentColor'}
        d="m60.21,14.52s-.02-.08-.04-.11c-.05-.14-.11-.28-.18-.4-.01-.02-.02-.04-.03-.05-.09-.14-.2-.26-.32-.38-.03-.03-.06-.05-.09-.07-.11-.09-.23-.17-.37-.24-.02,0-.03-.02-.05-.03L30.98.18c-.53-.24-1.14-.24-1.67,0L1.15,13.23c-.7.32-1.15,1.03-1.15,1.8s.45,1.48,1.15,1.8l10.75,4.98v13.53c0,.77.45,1.48,1.15,1.8l7,3.24c3.2,1.48,6.64,2.22,10.09,2.22,3.44,0,6.89-.74,10.09-2.22l7-3.24c.7-.32,1.15-1.03,1.15-1.8v-13.53l7.93-3.67v15.24c0,1.1.89,1.99,1.99,1.99s1.99-.89,1.99-1.99V15.03c0-.18-.03-.35-.07-.51Zm-15.8,19.55l-5.85,2.71c-2.67,1.24-5.54,1.85-8.42,1.85-2.87,0-5.75-.62-8.42-1.85l-5.85-2.71v-10.42l13.43,6.22c.53.24,1.14.24,1.67,0l13.43-6.22v10.42Zm1.15-15.33h0s-13.72,6.36-13.72,6.36l-1.7.79-11.81-5.47-3.62-1.68-8.01-3.71L30.14,4.17l23.43,10.85-8.01,3.71Z"
      />
    </g>
  </Icon>
);
