import { Text } from '@chakra-ui/layout';
import { FeatureModal } from './FeatureModal';
import { TrainingOptions } from '../../types';

interface TrainingModalProps {
  options: TrainingOptions;
  setOptions: (options: any) => void;
  isOpen: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}
export const TrainingModal = ({
  options,
  setOptions,
  isOpen,
  onSubmit,
  onCancel,
}: TrainingModalProps) => {
  return (
    <FeatureModal
      title={'Training Tutorials'}
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={onSubmit}
      displaySubmitButton={false}
    >
      <Text>Coming Soon</Text>
    </FeatureModal>
  );
};
