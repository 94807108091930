import { Box } from '@chakra-ui/layout';

type ChatHeaderBodyProps = {
  children: React.ReactNode;
};
export const ChatHeader = ({ children }: ChatHeaderBodyProps) => {
  return (
    <Box
      width={'100%'}
      height={'40px'}
      padding={'10px'}
      bgColor={'softGray'}
      my={'auto'}
      display={'flex'}
      textTransform={'uppercase'}
      fontWeight={'bold'}
    >
      {children}
    </Box>
  );
};
