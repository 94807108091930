import { Feature } from '../../types';
import {
  EmailComposerModal,
  SmsComposerModal,
  ObjectionResponseModal,
  VideoIdeasModal,
  SocialModal,
  TrainingModal,
} from './';

interface ModalSwitcherProps {
  currentFeature: Feature;
  options: any;
  setOptions: (options: any) => void;
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}

export const ModalSwitcher = ({
  currentFeature,
  options,
  setOptions,
  isOpen,
  onCancel,
  onSubmit,
}: ModalSwitcherProps) => {
  return (
    <>
      {currentFeature === Feature.Email && (
        <EmailComposerModal
          options={options}
          setOptions={setOptions}
          isOpen={isOpen}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      )}
      {currentFeature === Feature.Sms && (
        <SmsComposerModal
          options={options}
          setOptions={setOptions}
          isOpen={isOpen}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      )}
      {currentFeature === Feature.ObjectionResponse && (
        <ObjectionResponseModal
          options={options}
          setOptions={setOptions}
          isOpen={isOpen}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      )}
      {currentFeature.indexOf(Feature.Social) > -1 && (
        <SocialModal
          currentFeature={currentFeature}
          options={options}
          setOptions={setOptions}
          isOpen={isOpen}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      )}
      {currentFeature === Feature.VideoIdeas && (
        <VideoIdeasModal
          options={options}
          setOptions={setOptions}
          isOpen={isOpen}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      )}
      {currentFeature === Feature.Training && (
        <TrainingModal
          options={options}
          setOptions={setOptions}
          isOpen={isOpen}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      )}
    </>
  );
};
