import { ScaleFade, Tag, TagLabel } from '@chakra-ui/react';
import { Box, HStack } from '@chakra-ui/layout';
import { BsStars, BsTextParagraph } from 'react-icons/bs';
import { ReactNode } from 'react';
import { MdOutlineShortText } from 'react-icons/md';

interface Action {
  label: string;
  icon: ReactNode;
}

interface ChatActionsProps {
  onClick: (action: string) => void;
}

const actions: Action[] = [
  { label: 'Generate another response', icon: <BsStars /> },
  { label: 'Make longer', icon: <BsTextParagraph /> },
  { label: 'Make shorter', icon: <MdOutlineShortText /> },
  { label: 'Convert to Spanish', icon: <BsStars /> },
];

export const ChatActions = ({ onClick }: ChatActionsProps) => {
  return (
    <HStack>
      {actions.map((action, index) => {
        return (
          <ScaleFade
            initialScale={0.7}
            delay={0.25}
            in={true}
            key={`action-${index}`}
          >
            <Tag
              size={'lg'}
              _hover={{ cursor: 'pointer' }}
              onClick={() => onClick(action.label)}
            >
              <Box mr={'5px'}>{action.icon}</Box>
              <TagLabel>{action.label}</TagLabel>
            </Tag>
          </ScaleFade>
        );
      })}
    </HStack>
  );
};
