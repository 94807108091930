import { VStack } from '@chakra-ui/react';
import { Box, Text } from '@chakra-ui/layout';
import { ReactNode } from 'react';

interface SocialButtonProps {
  label: string;
  selected: boolean;
  icon: ReactNode;
  onClick: () => void;
}

export const SocialButton = ({
  label,
  selected,
  icon,
  onClick,
}: SocialButtonProps) => {
  return (
    <Box
      onClick={onClick}
      color={selected ? 'lagunaTeal' : 'default'}
      _hover={{ cursor: 'pointer', color: 'lagunaTeal' }}
    >
      <VStack>
        {icon}
        <Text fontSize={'xs'}>{label}</Text>
      </VStack>
    </Box>
  );
};
