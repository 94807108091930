import { create } from 'zustand';
import {
  ChatOptions,
  EmailOptions,
  Feature,
  ObjectionOptions,
  SmsOptions,
  SocialOptions,
  TrainingOptions,
  VideoOptions,
} from '../types';

type State = {
  options:
    | EmailOptions
    | SmsOptions
    | ObjectionOptions
    | SocialOptions
    | VideoOptions
    | TrainingOptions
    | ChatOptions;
  currentFeature: Feature;
};

type Actions = {
  setOptions: (options: any) => void;
  resetOptions: () => void;
  setCurrentFeature: (feature: Feature) => void;
  resetCurrentFeature: () => void;
};

export const useFeatureStore = create<State & Actions>((set) => ({
  //TODO: change default options
  // options: {
  //   tone: 'Sales person',
  //   length: '2 paragraphs',
  //   goal: '',
  //   message: 'I want to buy a ferrari',
  //   topic: 'Selling a car',
  // },
  options: {},
  currentFeature: Feature.Chat,
  setOptions: (options) => set({ options }),
  resetOptions: () => set({ options: {} }),
  setCurrentFeature: (currentFeature) => set({ currentFeature }),
  resetCurrentFeature: () => set({ currentFeature: Feature.Chat }),
}));
