import { Box, Flex } from '@chakra-ui/layout';
import { ChatHeader } from './ChatHeader';

type ChatLayoutBodyProps = {
  children: React.ReactNode;
  title?: string;
};

export const ChatLayoutBody = ({ children, title }: ChatLayoutBodyProps) => {
  return (
    <Box flex={1} h={title ? 'calc(100vh - 4em - 40px)' : 'calc(100vh - 4em)'}>
      {title && <ChatHeader>{title}</ChatHeader>}
      <Flex
        pb={'1em'}
        mx={'auto'}
        h={'100%'}
        backgroundImage={`url(sensai_mark_teal.svg)`}
        backgroundPosition={'center'}
        backgroundRepeat={'no-repeat'}
        backgroundSize={'auto 50%'}
      >
        {children}
      </Flex>
    </Box>
  );
};
