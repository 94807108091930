import { FormControl, FormLabel, Select } from '@chakra-ui/react';
import { HStack } from '@chakra-ui/layout';
import { Textarea } from '@chakra-ui/textarea';
import { Input } from '@chakra-ui/input';
import { FeatureModal } from './FeatureModal';
import { ObjectionOptions } from '../../types';
import { useState } from 'react';

interface ObjectionResponseModalProps {
  options: ObjectionOptions;
  setOptions: (options: any) => void;
  isOpen: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}
export const ObjectionResponseModal = ({
  options,
  setOptions,
  isOpen,
  onSubmit,
  onCancel,
}: ObjectionResponseModalProps) => {
  const [errors, setErrors] = useState<ObjectionOptions | null>(null);
  const validate = () => {
    const _errors: any = {};

    if (!options.tone) _errors['tone'] = 'Tone is required';
    if (!options.type) _errors['type'] = 'Type is required';

    if (Object.keys(_errors).length === 0) {
      onSubmit();
    } else {
      setErrors(_errors);
    }
  };
  return (
    <FeatureModal
      title={'Objection Response'}
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={validate}
    >
      <HStack mb={'1em'}>
        <FormControl isRequired={true} isInvalid={!!errors?.tone}>
          <FormLabel>Tone</FormLabel>
          <Select
            defaultValue={options.tone}
            onChange={(e) => setOptions({ ...options, tone: e.target.value })}
          >
            <option value=""></option>
            <option value="Sensai">Sensai</option>
            <option value="Manager">Manager</option>
            <option value="Sales person">Sales person</option>
          </Select>
        </FormControl>
        <FormControl isRequired={true} isInvalid={!!errors?.type}>
          <FormLabel>Type</FormLabel>
          <Select
            defaultValue={options.type}
            onChange={(e) => setOptions({ ...options, type: e.target.value })}
          >
            <option value=""></option>
            <option value="The Money">The Money</option>
            <option value="The Machine">The Machine</option>
            <option value="It's Me">It's Me</option>
          </Select>
        </FormControl>
      </HStack>
      <FormControl>
        <FormLabel>Objection</FormLabel>
        <Textarea
          placeholder={'Paste objection you received (if applicable)'}
          value={options.objection}
          onChange={(e) =>
            setOptions({ ...options, objection: e.target.value })
          }
        />
      </FormControl>
      <FormControl>
        <FormLabel>Response</FormLabel>
        <Input
          placeholder={'What do you want to say in your objection response?'}
          value={options.message}
          onChange={(e) => setOptions({ ...options, message: e.target.value })}
        />
      </FormControl>
    </FeatureModal>
  );
};
