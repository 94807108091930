import { Image } from '@chakra-ui/react';

export const SensaiLogo = (props: any) => {
  return (
    <Image
      src="/sensai_logo_black.svg"
      alt="Sensai Logo"
      width={120}
      height={24}
      {...props}
    />
  );
};
