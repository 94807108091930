import { Icon } from '@chakra-ui/icon';

export const SocialMediaIcon = (props: any) => (
  <Icon viewBox="0 0 75.48 75.48" {...props}>
    <defs>
      <style>
        {
          '\n      .cls-1 {\n        fill: #fff;\n        stroke-width: 0px;\n      }\n    '
        }
      </style>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path
          fill={'currentColor'}
          d="m67.7,0H7.78C3.48,0,0,3.48,0,7.78v48.01c0,4.3,3.48,7.78,7.78,7.78h17.22l11.34,11.34c.38.38.87.58,1.4.58s1.03-.21,1.4-.58l11.34-11.34h17.22c4.3,0,7.78-3.48,7.78-7.78V7.78c0-4.3-3.48-7.78-7.78-7.78Zm3.81,55.79c0,2.1-1.7,3.8-3.81,3.8h-18.04c-.52,0-1.03.21-1.4.58l-10.51,10.51-10.51-10.51c-.37-.37-.88-.58-1.4-.58H7.78c-2.1,0-3.8-1.7-3.81-3.8V7.78c0-2.1,1.7-3.8,3.81-3.81h59.93c2.1,0,3.8,1.7,3.81,3.81v48.01Z"
        />
        <rect
          fill={'currentColor'}
          x={44.01}
          y={11.92}
          width={17.57}
          height={3.97}
        />
        <rect
          fill={'currentColor'}
          x={44.01}
          y={23.84}
          width={17.57}
          height={3.97}
        />
        <rect
          fill={'currentColor'}
          x={37.74}
          y={47.67}
          width={23.84}
          height={3.97}
        />
        <rect
          fill={'currentColor'}
          x={13.9}
          y={47.67}
          width={11.92}
          height={3.97}
        />
        <rect
          fill={'currentColor'}
          x={13.9}
          y={35.75}
          width={47.67}
          height={3.97}
        />
        <path
          fill={'currentColor'}
          d="m37.74,27.81c.53,0,1.03-.21,1.4-.58.38-.38.58-.87.58-1.4v-11.92c0-.52-.21-1.03-.58-1.4-.37-.37-.88-.58-1.4-.58H13.9c-.52,0-1.03.21-1.4.58-.37.37-.58.88-.58,1.4v11.92c0,.52.21,1.03.58,1.4s.88.58,1.4.58h23.84ZM15.89,15.89h19.86v7.95s-19.86,0-19.86,0v-7.95Z"
        />
      </g>
    </g>
  </Icon>
);
