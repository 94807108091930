import { FormControl, FormLabel, Select } from '@chakra-ui/react';
import { HStack } from '@chakra-ui/layout';
import { Textarea } from '@chakra-ui/textarea';
import { Input } from '@chakra-ui/input';
import { FeatureModal } from './FeatureModal';
import { VideoOptions } from '../../types';
import { useState } from 'react';

interface VideoIdeasModalProps {
  options: VideoOptions;
  setOptions: (options: any) => void;
  isOpen: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}
export const VideoIdeasModal = ({
  options,
  setOptions,
  isOpen,
  onSubmit,
  onCancel,
}: VideoIdeasModalProps) => {
  const [errors, setErrors] = useState<VideoOptions | null>(null);
  const validate = () => {
    const _errors: any = {};

    if (!options.platform) _errors['platform'] = 'Platform is required';
    if (!options.length) _errors['length'] = 'Length is required';
    if (!options.subject) _errors['subject'] = 'Subject is required';

    if (Object.keys(_errors).length === 0) {
      onSubmit();
    } else {
      setErrors(_errors);
    }
  };
  return (
    <FeatureModal
      title={'Video Ideas'}
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={validate}
    >
      <HStack mb={'1em'}>
        <FormControl isRequired={true} isInvalid={!!errors?.platform}>
          <FormLabel>Platform</FormLabel>
          <Select
            defaultValue={options.platform}
            onChange={(e) =>
              setOptions({ ...options, platform: e.target.value })
            }
          >
            <option value=""></option>
            <option value="Facebook">Facebook</option>
            <option value="Instagram">Instagram</option>
            <option value="TikTok">TikTok</option>
            <option value="X">X</option>
            <option value="YouTube">YouTube</option>
          </Select>
        </FormControl>
        <FormControl isRequired={true} isInvalid={!!errors?.length}>
          <FormLabel>Length</FormLabel>
          <Select
            defaultValue={options.length}
            onChange={(e) => setOptions({ ...options, length: e.target.value })}
          >
            <option value=""></option>
            <option value="15s">15s</option>
            <option value="30s">30s</option>
            <option value="1 min">1 min</option>
            <option value="2 min">2 min</option>
          </Select>
        </FormControl>
      </HStack>
      <FormControl isRequired={true} isInvalid={!!errors?.subject}>
        <FormLabel>Subject</FormLabel>
        <Textarea
          placeholder={'What should your video be about?'}
          value={options.subject}
          onChange={(e) => setOptions({ ...options, subject: e.target.value })}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Special Occasion</FormLabel>
        <Input
          placeholder={
            'Is there a specific occasion that should be referenced (e.g. Memorial Day Sale)?'
          }
          value={options.occasion}
          onChange={(e) => setOptions({ ...options, occasion: e.target.value })}
        />
      </FormControl>
    </FeatureModal>
  );
};
