import { Feature } from './types';

interface FeatureConfig {
  endpoint: string;
  initialMessage: string;
}
export const getFeatureConfig = (
  feature: Feature,
  options: any,
): FeatureConfig => {
  const endpoint = `${process.env.REACT_APP_API_URL}/v1`;
  const config = { endpoint: '', initialMessage: '' };
  switch (feature) {
    case Feature.Email:
      config.endpoint = `${endpoint}/email`;
      config.initialMessage = `Email conveying: ${options.topic}\nLength: ${options.length}\nTone: ${options.tone}\nGoal: ${options.goal}`;
      break;
    case Feature.Sms:
      config.endpoint = `${endpoint}/sms`;
      config.initialMessage = `Text conveying: ${options.topic}\nLength: ${options.length}\nTone: ${options.tone}`;
      break;
    case Feature.ObjectionResponse:
      config.endpoint = `${endpoint}/objection`;
      config.initialMessage = `Objection: ${options.objection}\nType : ${options.type}\nTone: ${options.tone}`;
      break;
    case Feature.Social_X:
      config.endpoint = `${endpoint}/social/x`;
      config.initialMessage = `Social Platform: ${options.platform}\nTopic: ${options.topic}\nTone: ${options.tone}\nLength: ${options.length}\nHashTags: ${options.hashtags}\nEmojis: ${options.emojis}\nQuantity: ${options.quantity}`;
      break;
    case Feature.Social_TikTok:
      config.endpoint = `${endpoint}/social/tiktok`;
      config.initialMessage = `Social Platform: ${options.platform}\nTopic: ${options.topic}\nTone: ${options.tone}\nLength: ${options.length}\nHashTags: ${options.hashtags}\nEmojis: ${options.emojis}\nQuantity: ${options.quantity}`;
      break;
    case Feature.Social_Instagram:
      config.endpoint = `${endpoint}/social/instagram`;
      config.initialMessage = `Social Platform: ${options.platform}\nTopic: ${options.topic}\nTone: ${options.tone}\nLength: ${options.length}\nHashTags: ${options.hashtags}\nEmojis: ${options.emojis}\nQuantity: ${options.quantity}`;
      break;
    case Feature.Social_Facebook:
      config.endpoint = `${endpoint}/social/facebook`;
      config.initialMessage = `Social Platform: ${options.platform}\nTopic: ${options.topic}\nTone: ${options.tone}\nLength: ${options.length}\nHashTags: ${options.hashtags}\nEmojis: ${options.emojis}\nQuantity: ${options.quantity}`;
      break;
    case Feature.Social_LinkedIn:
      config.endpoint = `${endpoint}/social/linkedin`;
      config.initialMessage = `Social Platform: ${options.platform}\nTopic: ${options.topic}\nTone: ${options.tone}\nLength: ${options.length}\nHashTags: ${options.hashtags}\nEmojis: ${options.emojis}\nQuantity: ${options.quantity}`;
      break;
    case Feature.Social_YouTube:
      config.endpoint = `${endpoint}/social/youtube`;
      config.initialMessage = `Social Platform: ${options.platform}\nTopic: ${options.topic}\nTone: ${options.tone}\nLength: ${options.length}\nHashTags: ${options.hashtags}\nEmojis: ${options.emojis}\nQuantity: ${options.quantity}`;
      break;
    case Feature.VideoIdeas:
      config.endpoint = `${endpoint}/video`;
      config.initialMessage = `Video Platform: ${options.platform}\nVideo Length: ${options.length}\nSubject: ${options.subject}\nSpecial Occasion: ${options.occasion}`;
      break;
    case Feature.Training:
      config.endpoint = `${endpoint}/training`;
      break;
    default:
      config.endpoint = `${endpoint}/chat`;
      break;
  }
  return config;
};
