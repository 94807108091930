export interface Message {
  content: string;
  role: 'user' | 'assistant';
}

export enum Feature {
  Chat = 'chat',
  Email = 'email',
  Sms = 'sms',
  ObjectionResponse = 'objectionResponse',
  Social = 'social',
  Social_X = 'social_x',
  Social_TikTok = 'social_tiktok',
  Social_Instagram = 'social_instagram',
  Social_Facebook = 'social_facebook',
  Social_LinkedIn = 'social_linkedin',
  Social_YouTube = 'social_youtube',
  VideoIdeas = 'video',
  // Tasks = 'tasks',
  // Marketing = 'marketing',
  Training = 'training',
  // Inventory = 'inventory',
}

export type ChatOptions = {};

export type EmailOptions = {
  tone: string;
  length: string;
  goal: string;
  message: string;
  topic: string;
};

export type SmsOptions = {
  tone: string;
  length: string;
  message: string;
  topic: string;
};

export type ObjectionOptions = {
  tone: string;
  type: string;
  objection: string;
  message: string;
};

export enum SocialPlatform {
  Facebook = 'Facebook',
  Instagram = 'Instagram',
  LinkedIn = 'Linkedin',
  TikTok = 'TikTok',
  YouTube = 'YouTube',
  X = 'X',
}

export type SocialOptions = {
  platform: SocialPlatform;
  tone: string;
  length?: string;
  hashtags: string;
  emojis: string;
  quantity: string;
  topic: string;
};

export type VideoOptions = {
  platform: string;
  length: string;
  subject: string;
  occasion: string;
};

export type TrainingOptions = {
  environment: string;
  persona: string;
  vehicle: string;
  objection: string;
};
