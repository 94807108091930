import { Button } from '@chakra-ui/button';
import { PiNotePencil } from 'react-icons/pi';

export const NewChatButton = (props: any) => {
  return (
    <Button
      variant={'outline'}
      borderWidth={'--sensai-radii-md'}
      borderRadius={'0.375rem'}
      borderColor={'lagunaTeal'}
      color={'lagunaTeal'}
      w={'100%'}
      textAlign={'left'}
      rightIcon={<PiNotePencil />}
      {...props}
    >
      New Chat
    </Button>
  );
};
