import { Icon } from '@chakra-ui/icon';

export const EmailComposerIcon = (props: any) => (
  <Icon viewBox="0 0 75.48 75.48" {...props}>
    <defs>
      <style>
        {
          '\n      .cls-1 {\n        fill: #fff;\n        stroke-width: 0px;\n      }\n    '
        }
      </style>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path
          fill={'currentColor'}
          d="m75.42,28.34s-.03-.08-.04-.13c-.03-.11-.07-.21-.12-.31-.02-.04-.04-.09-.07-.13-.06-.1-.13-.19-.21-.28-.02-.03-.04-.06-.07-.08,0,0-.01-.01-.02-.02l-8.36-8.36V7.38c0-4.08-3.3-7.38-7.38-7.38H16.32c-4.08,0-7.38,3.3-7.38,7.38v11.66L.58,27.4s-.01.01-.02.02c-.03.03-.05.06-.07.09-.07.08-.14.17-.2.27-.03.05-.05.1-.08.15-.04.09-.08.18-.11.27-.02.05-.03.1-.05.15-.03.15-.06.3-.06.45v40.65c0,3.33,2.7,6.03,6.03,6.03h63.42c3.33,0,6.03-2.7,6.03-6.03V28.8c0-.16-.02-.31-.06-.46ZM12.91,17.88V7.38c0-1.88,1.53-3.4,3.41-3.41h42.85c1.88,0,3.4,1.53,3.41,3.41v24.66l-24.83,12.42-24.83-12.42v-10.19s0-1.99,0-1.99v-1.99Zm58.6,51.35v.22c0,1.14-.92,2.06-2.06,2.06H6.03c-1.14,0-2.06-.92-2.06-2.06v-37.43l4.97,2.48,1.99.99,1.99.99,23.94,11.97c.56.28,1.22.28,1.78,0l23.94-11.97,1.99-.99,1.99-.99,4.97-2.48v37.21Z"
        />
        <path
          fill={'currentColor'}
          d="m19.86,12.91h35.75c1.1,0,1.99-.89,1.99-1.99s-.89-1.99-1.99-1.99H19.86c-1.1,0-1.99.89-1.99,1.99s.89,1.99,1.99,1.99Z"
        />
        <path
          fill={'currentColor'}
          d="m19.86,21.85s0,0,0,0h35.75s0,0,0,0c1.1,0,1.98-.89,1.98-1.99s-.89-1.99-1.99-1.99H19.86c-1.1,0-1.99.89-1.99,1.99s.89,1.99,1.98,1.99Z"
        />
        <path
          fill={'currentColor'}
          d="m19.86,30.79h35.75c1.1,0,1.99-.89,1.99-1.99s-.89-1.99-1.99-1.99H19.86c-1.1,0-1.99.89-1.99,1.99s.89,1.99,1.99,1.99Z"
        />
      </g>
    </g>
  </Icon>
);
