import { Box } from '@chakra-ui/layout';
import { InputGroup, InputRightElement } from '@chakra-ui/input';
import { Button } from '@chakra-ui/button';
import { FaArrowUp } from 'react-icons/fa';
import { ScaleFade, Tooltip } from '@chakra-ui/react';
import AutoResizeTextarea from '../AutoResizeTextarea';
import { useEffect, useState } from 'react';

interface ChatInputProps {
  inputMessage: string;
  setInputMessage: (message: string) => void;
  sendMessage: () => void;
  handleKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const ChatInput = ({
  inputMessage,
  setInputMessage,
  sendMessage,
  handleKeyDown,
}: ChatInputProps) => {
  const defaultHeight = 48; // pixels
  const [height, setHeight] = useState<number>(defaultHeight);

  const onChange = (e: any) => {
    const contentHeight = e.target.scrollHeight;

    //TODO: improve logic to resize based on content with a max size
    if (contentHeight > defaultHeight) {
      setHeight(defaultHeight * 3);
    } else if (height !== defaultHeight) {
      setHeight(defaultHeight);
    }

    if (!e.target.value.replace(' ', '')) {
      setHeight(defaultHeight);
    }

    setInputMessage(e.target.value);
  };

  // resets the height after a message is sent
  useEffect(() => {
    if (inputMessage === '') {
      setHeight(defaultHeight);
    }
  }, [inputMessage]);

  return (
    <Box width={'50em'} height={`calc(3em+${height}px)`}>
      <ScaleFade initialScale={0.7} in={true}>
        <InputGroup>
          <AutoResizeTextarea
            placeholder="Ask Sensai..."
            size="lg"
            value={inputMessage}
            onChange={onChange}
            onKeyDown={handleKeyDown}
            h={`${height}px`}
          />
          <InputRightElement width="4.5rem" pt={'8px'}>
            <Tooltip label="Send message" placement="top">
              <Button
                size="md"
                h={'100%'}
                bgColor={inputMessage ? 'lagunaTeal' : 'bodyBg'}
                color={'white'}
                onClick={sendMessage}
              >
                <FaArrowUp />
              </Button>
            </Tooltip>
          </InputRightElement>
        </InputGroup>
      </ScaleFade>
    </Box>
  );
};
