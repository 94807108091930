import { FormControl, FormLabel, Select } from '@chakra-ui/react';
import { HStack } from '@chakra-ui/layout';
import { Textarea } from '@chakra-ui/textarea';
import { Input } from '@chakra-ui/input';
import { FeatureModal } from './FeatureModal';
import { EmailOptions } from '../../types';
import { useState } from 'react';

interface EmailComposerModalProps {
  options: EmailOptions;
  setOptions: (options: any) => void;
  isOpen: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}
export const EmailComposerModal = ({
  options,
  setOptions,
  isOpen,
  onSubmit,
  onCancel,
}: EmailComposerModalProps) => {
  const [errors, setErrors] = useState<EmailOptions | null>(null);
  const validate = () => {
    const _errors: any = {};

    if (!options.tone) _errors['tone'] = 'Tone is required';
    if (!options.length) _errors['length'] = 'Length is required';
    if (!options.goal) _errors['goal'] = 'Goal is required';
    if (!options.topic) _errors['topic'] = 'Topic is required';

    if (Object.keys(_errors).length === 0) {
      onSubmit();
    } else {
      setErrors(_errors);
    }
  };

  return (
    <FeatureModal
      title={'Email Composer'}
      isOpen={isOpen}
      onCancel={onCancel}
      onSubmit={validate}
    >
      <HStack mb={'1em'}>
        <FormControl isRequired={true} isInvalid={!!errors?.tone}>
          <FormLabel>Tone</FormLabel>
          <Select
            defaultValue={options.tone}
            onChange={(e) => setOptions({ ...options, tone: e.target.value })}
          >
            <option value=""></option>
            <option value="Sensai">Sensai</option>
            <option value="Manager">Manager</option>
            <option value="Sales person">Sales person</option>
          </Select>
        </FormControl>
        <FormControl isRequired={true} isInvalid={!!errors?.length}>
          <FormLabel>Length</FormLabel>
          <Select
            defaultValue={options.length}
            onChange={(e) => setOptions({ ...options, length: e.target.value })}
          >
            <option value=""></option>
            <option value="1 liner">1 Liner</option>
            <option value="2 sentences">2 Sentences</option>
            <option value="3 sentences">3 Sentences</option>
            <option value="1 paragraph">1 Paragraph</option>
            <option value="2 paragraphs">2 Paragraphs</option>
            <option value="3 paragraphs">3 Paragraphs</option>
          </Select>
        </FormControl>
        <FormControl isRequired={true} isInvalid={!!errors?.goal}>
          <FormLabel>Goal</FormLabel>
          <Select
            defaultValue={options.goal}
            onChange={(e) => setOptions({ ...options, goal: e.target.value })}
          >
            <option value=""></option>
            <option value="Lead Response">Lead Response</option>
            <option value="Follow Up">Follow Up</option>
            <option value="Appointment Schedule">Appointment Schedule</option>
            <option value="Trade-In">Trade-In</option>
            <option value="Review Request">Review Request</option>
            <option value="Customer Satisfaction">Customer Satisfaction</option>
            <option value="Service Reminder">Service Reminder</option>
            <option value="Referral Request">Referral Request</option>
            {/*<option value="none">None</option>*/}
          </Select>
        </FormControl>
      </HStack>
      <FormControl>
        <FormLabel>In response to</FormLabel>
        <Textarea
          placeholder={'Paste email you received (if applicable)'}
          value={options.message}
          onChange={(e) => setOptions({ ...options, message: e.target.value })}
        />
      </FormControl>
      <FormControl isRequired={true} isInvalid={!!errors?.topic}>
        <FormLabel>Topic of Email</FormLabel>
        <Input
          placeholder={'What do you want to convey in the email?'}
          value={options.topic}
          onChange={(e) => setOptions({ ...options, topic: e.target.value })}
        />
      </FormControl>
    </FeatureModal>
  );
};
