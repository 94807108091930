import { Box, HStack, Text } from '@chakra-ui/layout';
import { FaUserCircle } from 'react-icons/fa';
import { Spinner } from '@chakra-ui/react';
import { Message } from '../../types';
import { SensaiIcon } from '../icons/SensaiIcon';

interface ChatMessageProps {
  message: Message;
}
export const ChatMessage = ({ message }: ChatMessageProps) => {
  return (
    <HStack w={'48em'} mx={'auto'}>
      <Box h={'100%'} mb={'auto'} mt={'10px'}>
        {message.role === 'user' ? (
          <FaUserCircle size={'2em'} />
        ) : (
          <SensaiIcon w={'2em'} />
        )}
      </Box>
      <Box
        bgColor={'chatMessageBackground'}
        borderRadius={'10px'}
        p={'10px'}
        mb={'10px'}
      >
        {message.content ? (
          <Text whiteSpace={'pre-line'}>{message.content}</Text>
        ) : (
          <Spinner size={'sm'} />
        )}
      </Box>
    </HStack>
  );
};
