import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { Button } from '@chakra-ui/button';
import { ReactNode } from 'react';

interface FeatureModalProps {
  title: string;
  submitButtonText?: string;
  displaySubmitButton?: boolean;
  isOpen: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  children?: ReactNode;
}

export const FeatureModal = ({
  title,
  submitButtonText = 'Submit',
  displaySubmitButton = true,
  isOpen,
  onSubmit,
  onCancel,
  children,
}: FeatureModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      onOverlayClick={onCancel}
      onEsc={onCancel}
      size={'xl'}
      motionPreset={'slideInBottom'}
      isCentered
    >
      <ModalOverlay />
      <ModalContent maxW={'50%'}>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>

        <ModalFooter>
          {displaySubmitButton && (
            <Button
              colorScheme="blue"
              mr={3}
              onClick={onSubmit}
              type={'submit'}
            >
              {submitButtonText}
            </Button>
          )}
          {/*<Button variant="ghost">Secondary Action</Button>*/}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
