import { Box } from '@chakra-ui/layout';
import { ReactNode } from 'react';

type ChatLayoutOptionsProps = {
  children?: ReactNode;
};

export const ChatThreadsContainer = ({ children }: ChatLayoutOptionsProps) => {
  return (
    <Box
      bgColor={'midnightPurple'}
      color={'white'}
      width={'20em'}
      padding={'10px'}
    >
      {children}
    </Box>
  );
};
