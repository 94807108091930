import { Textarea } from '@chakra-ui/textarea';

const AutoResizeTextarea = (props: any) => {
  return (
    <Textarea
      rows={1}
      style={{ lineHeight: '30px', resize: 'none' }} // Adjust this based on your CSS
      {...props}
    />
  );
};

export default AutoResizeTextarea;
