import { HStack } from '@chakra-ui/layout';
import styles from '../../css/page.module.css';
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
} from '@chakra-ui/react';
import { CgProfile } from 'react-icons/cg';
import { FiLogOut } from 'react-icons/fi';
import { Icon } from '@chakra-ui/icon';
import { FaCog } from 'react-icons/fa';
import { AdminPortal } from '@frontegg/js';
import ColorModeToggleButton from '../buttons/ColorModeToggleButton';
import { SensaiLogo } from '../icons';
import { ContextHolder } from '@frontegg/react';

const ProfileIcon = () => {
  return (
    <Icon
      as={CgProfile}
      boxSize={8}
      color={'white'}
      _hover={{ color: 'lagunaTeal' }}
    />
  );
};

export const Header = () => {
  const logout = () => {
    const baseUrl = ContextHolder.getContext().baseUrl;
    window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location}`;
  };

  return (
    <HStack bgColor={'electricPurple'} h={'4em'} px={5}>
      <SensaiLogo className={styles.invert} />
      <Spacer />
      <ColorModeToggleButton aria-label={'toggle-color-mode-button'} />
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<ProfileIcon />}
          variant="solid"
          bg={'transparent'}
          _hover={{ bg: 'transparent' }}
          _active={{ bg: 'transparent' }}
        />
        <MenuList>
          <MenuItem icon={<FaCog />} onClick={() => AdminPortal.show()}>
            Settings
          </MenuItem>
          <MenuItem icon={<FiLogOut />} onClick={() => logout()}>
            Logout
          </MenuItem>
        </MenuList>
      </Menu>
    </HStack>
  );
};
