import { ChakraProvider, Spinner } from '@chakra-ui/react';
import { theme } from './theme';
import { useAuth, useLoginWithRedirect } from '@frontegg/react';
import { useEffect } from 'react';
import { Center } from '@chakra-ui/layout';

interface ProvidersProps {
  children: React.ReactNode;
}

export function Providers({ children }: ProvidersProps) {
  const { isAuthenticated } = useAuth();
  const loginWithRedirect = useLoginWithRedirect();

  useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect();
    }
  }, [isAuthenticated, loginWithRedirect]);

  return (
    <ChakraProvider theme={theme}>
      <>
        {isAuthenticated ? (
          <>{children}</>
        ) : (
          <Center h={'100vh'}>
            <Spinner
              w={'100px'}
              h={'100px'}
              thickness="4px"
              speed="0.65s"
              color="electricPurple"
            />
          </Center>
        )}
      </>
    </ChakraProvider>
  );
}
