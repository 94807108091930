import React from 'react';
import {
  useColorMode,
  IconButton,
  IconButtonProps,
  Tooltip,
} from '@chakra-ui/react';
import { FaMoon, FaSun } from 'react-icons/fa';
import { Icon } from '@chakra-ui/icon';

const MoonIcon = () => {
  return (
    <Icon
      as={FaMoon}
      // boxSize={5}
      color={'white'}
      _hover={{ color: 'lagunaTeal' }}
    />
  );
};

const ColorModeToggleButton: React.FC<IconButtonProps> = (props) => {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <Tooltip label="Toggle color mode" placement="left">
      <IconButton
        icon={colorMode === 'light' ? <MoonIcon /> : <FaSun />}
        variant={'ghost'}
        size={'lg'}
        bg={'transparent'}
        _hover={{ bg: 'transparent', color: 'lagunaTeal' }}
        onClick={toggleColorMode}
        {...props}
      />
    </Tooltip>
  );
};

export default ColorModeToggleButton;
